<template>
    <modal ref="modalEnviarEncuesta" titulo="Enviar encuesta" adicional="Enviar" tamano="modal-md" @adicional="guardarValores">
        <ValidationObserver ref="validation">
            <div class="row justify-content-center mx-0 py-3">
                <ValidationProvider rules="required" name="inicio" v-slot="{ errors }" tag="div">
                    <p class="f-12 pl-2 label-inputs text-left">Día de inicio</p>
                    <el-date-picker v-model="vigencia.inicio" type="date" format="dd [de] MMMM yyyy" value-format="yyyy-MM-dd" :picker-options="pickerOptions" placeholder="Seleccionar" size="small" />
                    <p class="text-danger f-10">{{ errors[0] }}</p>
                </ValidationProvider>
            </div>
            <div class="row justify-content-center mx-0 py-3">
                <ValidationProvider rules="required" name="fin" v-slot="{ errors }" tag="div">
                    <p class="f-12 pl-2 label-inputs text-left">Día de finalización</p>
                    <el-date-picker v-model="vigencia.fin" type="date" format="dd [de] MMMM yyyy" value-format="yyyy-MM-dd" :picker-options="pickerOptions" placeholder="Seleccionar" size="small" />
                    <p class="text-danger f-10">{{ errors[0] }}</p>
                </ValidationProvider>
            </div>
        </ValidationObserver>
    </modal>
</template>
<script>
import moment from 'moment'
export default {
    data(){
        return{
          pickerOptions: {
            disabledDate(time) {
              return time.getTime() < moment().subtract(1,'day');
            },
          },
          vigencia : {
            inicio:'',
            fin:''
          }
        }
    },
    methods:{
        toggle(){
            this.$refs.modalEnviarEncuesta.toggle()
        },
        async guardarValores(){
          let valid = await this.$refs.validation.validate()
          if(valid){
            this.$emit('guardar', this.vigencia)
          }
        },
        reset(){
          this.vigencia = {
            inicio:'',
            fin:''
          }
        }
    }
}
</script>
<style lang="scss" scoped>

</style>